import React from 'react'
import { Link } from 'gatsby'
import sanitizeHtml from 'sanitize-html'
import {
    effects,
    useAnimationManager,
} from './custom-hooks/useAnimationManager'
import Portfolio from './home-components/portfolio'
import HeroVideo from './home-components/heroVideo'
import Chapters from './project-components/chapters'
import { allowance } from '../utils'

import * as homeStyles from './home.module.scss'

const HomePage = ({ payload }) => {
    const animatedElements = {
        runOpeningAnimation: {
            id: '',
            effect: effects.ON_OPEN,
        },
    }

    const animationStates = useAnimationManager(animatedElements)

    const getAnimatedHeader = (html, color) => {
        const cleanHtml = sanitizeHtml(html, allowance)
        const pieces = cleanHtml.split('<br />')
        return pieces.map(element => (
            <div
                className={
                    animationStates.runOpeningAnimation
                        ? homeStyles.headerHide
                        : homeStyles.headerHideClosed
                }
                key={element}
            >
                <h1
                    style={{
                        color: color,
                    }}
                >
                    {element.replace(/[\r\n]+/gm, '')}
                </h1>
            </div>
        ))
    }

    return (
        <React.Fragment>
            <div
                style={{
                    backgroundColor:
                        payload.wpPage.home_page.heroBackgroundColor,
                    color: payload.wpPage.home_page.heroTextColor,
                }}
            >
                <div className={homeStyles.greeterContainer}>
                    <div className={homeStyles.headerBox}>
                        {getAnimatedHeader(
                            payload.wpPage.home_page.missionStatement,
                            payload.wpPage.home_page.heroTextColor
                        )}
                    </div>
                    <div
                        className={
                            animationStates.runOpeningAnimation
                                ? homeStyles.approachBox
                                : homeStyles.approachBoxClosed
                        }
                    >
                        <Link
                            style={{
                                color:
                                    payload.wpPage.home_page.heroTextColor,
                                borderColor:
                                    payload.wpPage.home_page.heroTextColor,
                            }}
                            className={homeStyles.approach}
                            to="/about"
                        >
                            See our approach <span>+</span>
                        </Link>
                    </div>
                </div>
            </div>
            <HeroVideo />
            <Chapters
                chapters={payload.wpPage.home_page.chapters}
                pageId={payload.wpPage.id}
                noSeparator
            />
            {!payload.wpPage.home_page.hideProjectSlider ? (
                <Portfolio
                    projectsToShow={payload.wpPage.home_page.projectsShown.map(
                        p => p.project.title
                    )}
                />
            ) : null}
        </React.Fragment>
    )
}

export default HomePage
