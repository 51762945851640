import React from 'react'
import Layout from '../components/layout'
import SEO from '../components/seo'
import Home from '../components/home'
import useHomeData from '../components/custom-hooks/useHomeData'

const IndexPage = () => {
    const payload = useHomeData()
    return (
        <Layout color={payload.wpPage.home_page.heroTextColor} backgroundColor={payload.wpPage.home_page.heroBackgroundColor}>
            <SEO title='Home' />
            <Home payload={payload} />
        </Layout>
    )
}

export default IndexPage
