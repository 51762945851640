import React from 'react'
import useServicesData from '../custom-hooks/useServicesData'

import * as servicesStyles from './heroVideo.module.scss'

const PortfolioPage = props => {
    const payload = useServicesData()
    return (
        <div
            style={{ background: payload.wpPage.home_page.videoBackgroundColor }}
            className={servicesStyles.coloredContainer}
        >
            <div className={servicesStyles.videoContainer}>
                <video autoPlay muted loop playsInline>
                    <source
                        src={payload.wpPage.home_page.heroVideo.mediaItemUrl}
                        type='video/mp4; codecs="avc1.42E01E, mp4a.40.2"'
                    />
                    <img
                        src={
                            payload.wpPage.home_page.heroImageFallback
                                .sourceUrl
                        }
                        alt={
                            payload.wpPage.home_page.heroImageFallback
                                .altText
                        }
                    />
                </video>
            </div>
        </div>
    )
}

export default PortfolioPage
