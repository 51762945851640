import { useStaticQuery, graphql } from 'gatsby'

export default () => {
    const payload = useStaticQuery(
        graphql`
            query {
                wpPage(title: { eq: "Home" }) {
                    id
                    home_page {
                        missionStatement
                        heroBackgroundColor
                        heroTextColor
                        hideProjectSlider
                        projectsShown {
                            project {
                                ... on WpProject {
                                    id
                                    title
                                }
                            }
                        }
                        chapters {
                            contentBlock {
                                __typename
                                ... on WpPage_HomePage_chapters_ContentBlock_Quote {
                                    backgroundColour
                                    client
                                    quotee
                                    statement
                                    textColor
                                }
                                ... on WpPage_HomePage_chapters_ContentBlock_Metric {
                                    backgroundColour
                                    metrics {
                                        __typename
                                        ... on WpPage_HomePage_chapters_ContentBlock_Metric_Metrics_DescriptionMetric {
                                            count
                                            description
                                            title
                                        }
                                        ... on WpPage_HomePage_chapters_ContentBlock_Metric_Metrics_FactMetric {
                                            factDescription
                                            unit
                                            title
                                            scale
                                        }
                                        ... on WpPage_HomePage_chapters_ContentBlock_Metric_Metrics_NumberMetric {
                                            integer
                                            title
                                        }
                                    }
                                    noMetrics
                                    textColour
                                    useCustomTextColour
                                }
                                ... on WpPage_HomePage_chapters_ContentBlock_Image {
                                    backgroundColour
                                    blockBackgroundColour
                                    fieldGroupName
                                    description
                                    heading
                                    horizontalAlignment
                                    imageAlignment
                                    imageScaleFactor
                                    imageTopPositionShift
                                    overlayColor
                                    overlayOpacity
                                    showHeadings
                                    showText
                                    subheading
                                    textColour
                                    image {
                                        localFile {
                                            childImageSharp {
                                                gatsbyImageData(
                                                    layout: CONSTRAINED
                                                )
                                            }
                                        }
                                    }
                                    videoInstead {
                                        mediaItemUrl
                                    }
                                    metricsFields {
                                        fieldGroupName
                                        metricDescription
                                    }
                                    relatedLogo {
                                        sourceUrl
                                    }
                                }
                                ... on WpPage_HomePage_chapters_ContentBlock_ImageScroller {
                                    backgroundColour
                                    description
                                    heading
                                    horizontalAlignment
                                    lastFirst
                                    resolution
                                    showHeadings
                                    showText
                                    subheading
                                    textColour
                                    pics {
                                        image {
                                            localFile {
                                                childImageSharp {
                                                    gatsbyImageData(
                                                        layout: CONSTRAINED
                                                    )
                                                }
                                            }
                                        }
                                    }
                                }
                                ... on WpPage_HomePage_chapters_ContentBlock_ImageCollection {
                                    backgroundColour
                                    description
                                    heading
                                    horizontalAlignment
                                    imageOverlay
                                    mixedMedia {
                                        __typename
                                        ... on WpPage_HomePage_chapters_ContentBlock_ImageCollection_MixedMedia_Video {
                                            autoplay
                                            loop
                                            noControls
                                            video {
                                                mediaItemUrl
                                            }
                                        }
                                        ... on WpPage_HomePage_chapters_ContentBlock_ImageCollection_MixedMedia_Image {
                                            image {
                                                localFile {
                                                    childImageSharp {
                                                        gatsbyImageData(
                                                            layout: CONSTRAINED
                                                        )
                                                    }
                                                }
                                            }
                                        }
                                    }
                                    showHeadings
                                    showText
                                    sidetextAlignment
                                    stickyText
                                    subheading
                                    textColour
                                    textOnTheSide
                                }
                                ... on WpPage_HomePage_chapters_ContentBlock_Slider {
                                    backgroundColour
                                    description
                                    heading
                                    horizontalAlignment
                                    showHeadings
                                    showText
                                    sliderResolution
                                    subheading
                                    textColour
                                    layout
                                    cards {
                                        cardColour
                                        fieldGroupName
                                        heading
                                        showImage
                                        text
                                        image {
                                            localFile {
                                                childImageSharp {
                                                    gatsbyImageData(
                                                        layout: CONSTRAINED
                                                    )
                                                }
                                            }
                                        }
                                    }
                                }
                                ... on WpPage_HomePage_chapters_ContentBlock_Video {
                                    autoplay
                                    backgroundColour
                                    controls
                                    fieldGroupName
                                    video {
                                        mediaItemUrl
                                    }
                                }
                                ... on WpPage_HomePage_chapters_ContentBlock_Services {
                                    backgroundColour
                                    fieldGroupName
                                    servicesBlockTitle
                                    textColour
                                    servicePosts {
                                        serviceDescription
                                        serviceName
                                    }
                                }
                                ... on WpPage_HomePage_chapters_ContentBlock_Logos {
                                    backgroundColour
                                    description
                                    horizontalAlignment
                                    heading
                                    showHeadings
                                    showText
                                    subheading
                                    textColour
                                }
                                ... on WpPage_HomePage_chapters_ContentBlock_Text {
                                    backgroundColour
                                    description
                                    heading
                                    horizontalAlignment
                                    showHeadings
                                    showText
                                    subheading
                                    textColour
                                }
                            }
                        }
                    }
                }
            }
        `
    )
    return payload
}
