import { useStaticQuery, graphql } from 'gatsby'

export default () => {
    const payload = useStaticQuery(
        graphql`
            query {
                wpPage(
                    title: { eq: "Home" }
                ) {
                    home_page {
                        heroImageFallback {
                            altText
                            sourceUrl
                        }
                        heroVideo {
                            mediaItemUrl
                        }
                        videoBackgroundColor
                    }
                }
            }
        `
    )
    return payload
}
